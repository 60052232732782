import React from 'react';
import classes from './PrivacyPolicy.module.css';

const PrivacyPolicy = () => {
  return (
    <div class={`${classes.privacyPolicy} container my-5`}>
      <h1>プライバシーポリシー</h1>
      <h2>個人情報保護方針</h2>
      <div className={classes.docInfo}>
        <p>
          株式会社 教育測定研究所<br />
          代表取締役社長 西田 紀子<br />
          平成16年5月17日 制定<br />
          令和3年1月21日 改訂<br />
        </p>
      </div>
      <div className={classes.introMessage}>
        <p>
          株式会社教育測定研究所(以下、当社)は、最先端の教育測定技術とIT技術を活用した教育サービスを提供し、学校、企業が行う教育活動や人材育成、また個人の学習活動への支援を通じて社会に貢献することを目指しています。<br />
          当社の業務に従事するすべての者(以下「従業者」という。)は、個人情報の取扱いに関する法令、国が定める指針その他の規範を遵守し、個人情報を正確かつ安全に取り扱うことにより、お客様、従業員および関係者すべての情報を適切に管理し、その信頼に応えます。<br />
          特に当社の主要な事業分野であるインターネットを通じた教育サービスにおけるお客様の個人情報の取扱いについては、事故のないよう最善の注意を払うとともに、お客様に不安や疑念を与えることのないよう、当社の個人情報の取扱いに関する情報の開示、発信を積極的に行います。
        </p>
      </div>
      <h3>1． 基本方針</h3>
      <p>当社は、業務実態に応じた適切な個人情報の取得、利用及び提供を行います。</p>
      <h4>(1) 個人情報の利用目的の特定について</h4>
      <p>個人情報を取扱うに当たっては、その利用の目的（以下「利用目的」という。）をできる限り特定します。</p>
      <h4>(2) 個人情報の取扱いについて</h4>
      <p>個人情報を取扱うに当たっては、特定された利用目的の達成に必要な範囲を超えた個人情報の取扱い（以下、「目的外利用」という）を行いません。また、「目的外利用」を行わないための措置を講じます。</p>
      <h3>2． 法令等の遵守について</h3>
      <p>すべての従業者は、個人情報の取扱いに関する法令、国が定める指針その他の規範を遵守します。</p>
      <h3>3． 安全対策について</h3>
      <p>当社は、個人情報の漏えい、滅失又はき損の防止及び是正を実施します。そのため、個人情報を取り扱う各業務のリスクを分析し、適切な安全対策を講じるため、個人情報保護マネジメントシステムにおいて安全対策を規定し、これを実施します。</p>
      <h3>4． 苦情・相談等について</h3>
      <p>当社は、個人情報に関する個人の権利を尊重し、個人情報に関する苦情、相談に適切かつ迅速に対応いたします。</p>
      <h3>5． 継続的改善について</h3>
      <p>当社の事業内容や社会情勢の変化にも対応できるよう継続的に個人情報保護マネジメントシステムを見直し、改善していきます。</p>
      <h3>●個人情報お問い合わせ窓口</h3>
      <h4><b>【弊社の個人情報取り扱いに関するお問い合わせ】</b></h4>
      <div className={classes.contactInfo}>
        <h4>株式会社教育測定研究所 個人情報取り扱いに関する苦情・相談窓口</h4>
        <dl>
          <dt>電話：</dt>
          <dd>03-6897-3856</dd>
        </dl>
        <dl>
          <dt>E-mail：</dt>
          <dd>
            <a href="&#109;ai&#108;&#116;o&#58;&#99;&#111;&#110;t&#97;&#99;&#116;&#64;j&#105;e&#109;&#46;c&#111;.&#106;p">
              &#99;&#111;&#110;t&#97;ct&#64;&#106;i&#101;m&#46;&#99;&#111;.&#106;p
            </a>
          </dd>
        </dl>
        <p>午前9時30分～午後5時30分（土・日曜日、祝祭日、年末年始は除く）</p>
      </div>
      <h4><b>【認定個人情報保護団体の名称及び、苦情の解決の申出先】</b></h4>
      <p>弊社は、個人情報保護委員会の認定を受けた下記の認定個人情報保護団体の対象事業者です。同協会では、対象事業者の個人情報に関する苦情を受け付けております。</p>
      <p>
        認定個人情報保護団体の名称： 一般社団法人日本情報システム・ユーザー協会（JUAS）<br />
        苦情の解決の申出先： 認定個人情報保護団体事務局 苦情相談室<br />
        住所： 東京都中央区築地 1-13-14 NBF東銀座スクエア2階<br />
        電話番号：03-6264-1318<br />
        受付時間：午前10時00分〜午後4時00分（土・日曜日、祝祭日は除く）<br />
        ※弊社の商品・サービスに関するお問い合わせ先ではございません。
      </p>
      <hr />
      <h2>当社における個人情報の取扱について</h2>
      <div className={classes.docInfo}>
        <p>
          株式会社 教育測定研究所<br />
          個人情報保護管理者<br />
          平成16年5月17日 制定<br />
          令和3年1月21日 最終改訂
        </p>
      </div>
      <h3>Ⅰ 当社が扱う個人情報の利用目的</h3>
      <p>当社では、以下の「当社の業務内容」を遂行するために、「利用目的」の項に記載する利用目的で、個人情報を取得し利用いたします。</p>
      <p><b>【当社の業務内容】</b></p>
      <ol className={classes.decimal}>
        <li>テスティング事業（コンピュータ適応型テストや連続測定型テストなど最新のテスト理論を用いた、各種テストや学習システムの提供）の業務</li>
        <li>テスト分析事業（資格試験の構築支援、現状テスト項目の見直し、既存テストのコンピュータ化など、「正しく測定したい」方の支援事業）の業務</li>
        <li>テスト・オペレーション事業（テスト問題作成、印刷、配送、採点、集計、分析等、テストの実施・運営をワンストップで提供）の業務</li>
        <li>当社が運営しているWebサイト運営業務</li>
        <li>当社が公益財団法人日本英語検定協会と共同で運営しているWebサイト運営業務</li>
        <li>前各号に付帯するコンサルティング、教育、出版、セミナー・イベントの開催、技術情報の提供、および技術要員の派遣等専門サービスの提供業務</li>
        <li>その他前各号に付帯する一切の業務</li>
      </ol>
      <p><b>【利用目的】</b></p>
      <ol className={classes.decimal}>
        <li>
          上記の「当社の業務内容」に記載された当社の業務、お取引様とのお取引を実施するため
          <ol className={classes.decimalLeadingZero}>
            <li>見積書・納品書等の送付、事務連絡</li>
            <li>契約の締結</li>
            <li>ライセンス供与、ユーザ登録</li>
            <li>受託システム開発、システムの運用管理業務の受託等の受託業務の実施</li>
            <li>保守契約に基づく保守サポートサービス業務の実施</li>
            <li>債権債務および契約義務の履行</li>
            <li>新製品情報、契約期限切れご案内、パッチリリース情報提供等のお取引の実施に伴うアフターサービスの実施</li>
            <li>事業活動のご案内</li>
            <li>研究・開発・改善のためのアンケート調査（連絡、実施、分析、報告）</li>
            <li>契約先、納入先、請求先、保守連絡先等の把握および連絡網の整備</li>
            <li>その他、お取引に伴う業務上の諸ご連絡、資料、挨拶状の送付等のため</li>
          </ol>
        </li>
        <li>
          当社が取り扱うサービス・ソリューション等の情報提供（ダイレクトメールの発送、セミナー・イベント等のご案内）のため
          <div style={{ marginLeft: '14px', overflow: 'hidden' }}>
            <p>
              当社とのお取引に伴い当社が保有することとなった個人情報のご本人に対して、個別に事前にご了解を得た上で、および当社のWebサイトより情報提供に関するお申し込みをされたご本人に対して、当社が取り扱うサービス・ソリューション等の情報提供を目的としてダイレクトメールの発送やメールマガジンの発行を行ないます。
            </p>
          </div>
        </li>
        <li>当社が主催・共催・後援するセミナー・イベント等への参加申込者に対するご連絡、受講事務手続き、およびセミナー・イベント実施結果の分析・統計、事後の改善のため</li>
        <li>他の業者から業務の委託または個人情報および特定個人情報の処理の委託を受けた場合、当該受託業務を適切に遂行するため</li>
        <li>お取引先様からのお問い合わせやご意見に関するご対応や、ご請求いただいた資料等の送付のため</li>
        <li>報道関係者、各種ステークホルダー向けの講演会や説明会の案内状の送付および各種ご連絡のため</li>
        <li>当社の株主に対する各種案内状の送付、ご連絡、諸事務手続きのため</li>
        <li>ご本人確認のための検討、調査、対応およびご連絡、諸事務手続きのため</li>
        <li>セキュリティ確保のための外来者・来訪者および来訪目的等を確認し管理するため</li>
        <li>当社の採用活動に関して、円滑な手続きを行うためのご連絡やご対応、資料送付等のため</li>
        <li>法令に基づき必要とされる手続きを行うため</li>
        <li>
          当社採用基準の充足を確認するため
          <ol className={classes.decimalLeadingZero}>
            <li>法令に基づき必要とされる手続きを行う</li>
            <li>当社採用基準の充足確認</li>
            <li>採用選考において本人の適性・能力の判断</li>
            <li>入社までの過程において緊急時の連絡</li>
          </ol>
        </li>
        <li>業務上の緊急連絡のため</li>
        <li>各種支払調書作成のため</li>
        <li>その他通常の会社運営を行なうにあたり、物品の購入、作業の受委託、金融取引等を実施するため</li>
      </ol>
      <h3>Ⅱ 当社が取り扱う保有個人データに関する事項</h3>
      <h3>1． 当該個人情報取扱事業者の名称および個人情報保護管理者</h3>
      <p>
        名称： 株式会社教育測定研究所<br />
        個人情報保護管理者： 株式会社教育測定研究所 副社長付 Pマーク・ISMS事務局
      </p>
      <h3>2. 開示対象個人情報(※1)の開示等の求めの手続き</h3>
      <p>弊社では、開示対象個人情報の開示等の求めに関して、本人またはその代理人からの「利用目的の通知」「開示」「訂正等」「利用停止等」の求めを郵送にて承っております。下記を弊社「個人情報苦情及びご相談窓口」までご郵送ください。できる限り迅速に対応いたします。</p>
      <p>
        (※1) 「開示対象個人情報」とは、電子計算機を用いて検索することができるように体系的に構成した情報の集合物又は一定の規則に従って整理、分類し、目次、索引、符合などを付すことによって特定の個人情報を容易に検索できるように体系的に構成した情報の集合物を構成する個人情報であって、当社が、本人から求められる利用目的の通知、開示、内容の訂正、追加又は削除、利用の停止、消去及び第三者への提供の停止の求めのすべてに応じることができる権限を有するものをいいます。詳しくは、「個人情報お問い合わせ窓口」までご連絡ください。
      </p>
      <p>&nbsp; </p>
      <p>① 弊社指定の申込書</p>
      <div style={{ marginLeft: '12px', overflow: 'hidden' }}>
        <p>
          「開示等の求め」の申込書は、「開示等の請求等申請書」をご利用ください。お手元にない場合は、「個人情報お問い合わせ窓口」にご連絡ください。こちらからお送りいたします。
        </p>
      </div>
      <p>&nbsp; </p>
      <p>② 本人確認書類<br />
        <ol className={classes.decimalLeadingZero}>
          <li>
            ご本人によるお申し込みの場合<br />
            不要（当社からご本人であることを確認する連絡をさせていただくことがあります）
          </li>
          <li>
            代理人によるお申し込みの場合<br />
            下記の書類のうち該当するもののすべて。
            <ol className={classes.privacyLowerLatin}>
              <li>
                親権者（または未成年被後見人)の場合<br />
                ○本人の住所・本籍を確認できる公的証明書(※2)のコピー<br />
                ○戸籍謄本(全部事項証明)1通のコピー<br />
                ○代理人の住所・本籍を確認できる公的証明書(※2)のコピー
              </li>
              <li>
                成年後見人(成年被後見人の法定代理人)の場合<br />
                ○本人の住所を確認できる公的証明書(※2)のコピー<br />
                ○「登記事項証明書」1通のコピー(本人の法定代理人であることがわかるもの)<br />
                ○代理人の住所を確認できる公的証明書(※2)のコピー
              </li>
              <li>
                委任状による代理人の場合<br />
                ○本人の印鑑証明書のコピー<br />
                ○当社指定の委任状(本人の印鑑証明書で使用している印鑑を捺印したもの)<br />
                （当社指定の委任状がお手元にない場合は、ご連絡ください。早急にご郵送します）<br />
                ○代理人の住所を確認できる公的証明書(※2)のコピー(弁護士の場合は登録番号でも可)
              </li>
            </ol>
          </li>
        </ol>
      </p>
      <p>
        (※2)「公的証明書」とは、運転免許証、各種健康保険証・年金手帳等、写真付き住民基本台帳カード、旅券(パスポート)、外国人登録証明書、印鑑登録証明書、住民票の写し、戸籍謄本・抄本、外国人登録原票の写し などを言います。
      </p>
      <p>
        ※機微な個人情報の取扱い： ご提出いただく本人確認書類の種類によっては、本籍地等の機微な情報を取扱うことがございます。本籍地等の機微な個人情報に該当する箇所については黒く塗りつぶして隠すなどの処置をお願いいたします。
      </p>
      <p>&nbsp; </p>
      <p>③ 手数料</p>
      <div style={{ marginLeft: '12px', overflow: 'hidden' }}>
        <p>
          「利用目的の通知」あるいは「開示」につきましては、1件のお申し込みにつき手数料として1,000円いただきます。1,000円分の郵便小為替を上記書類にあわせてご同封ください。
        </p>
        <p>
          上記の通り手数料が同封されていなかった場合は、その旨ご連絡申し上げますが、所定の期間内にお支払いいただけない場合は開示等の求めがなかったものといたします。なお、送付頂いた書類は原則としてご返却いたしません。
        </p>
      </div>
      <p>&nbsp; </p>
      <p>
        ＜送付先＞<br />
        〒108-0075 東京都港区港南1-8-15 Wビル 13F<br />
        株式会社教育測定研究所 個人情報保護管理者宛て
      </p>
      <p>&nbsp; </p>
      <p>
        ※注意事項<br />
        <ul className={classes.marker}>
          <li>郵送途中の書類の紛失、事故による未着につきましては、弊社では責任を負いかねます。</li>
          <li>必要事項を全てご記入ください。書類に不備がある場合は、返送させていただく場合がございます。</li>
          <li>個人情報保護法の例外規定にあたる場合など、開示等の求めにお答えできない場合がございます。</li>
          <li>内容により、お時間をいただく場合がございます。</li>
          <li>開示等の求めにともない収集した個人情報は、開示等の求めの必要な範囲のみで取扱うものとします。提出していただいた書面は弊社で適切に廃棄いたします。</li>
          <li>「訂正等」、「利用停止等」の結果、該当するサービスがご利用いただけなくなることがあります。あらかじめご了承ください。</li>
        </ul>
      </p>
      <h3>3. 16歳未満のお客様の情報保護</h3>
      <p>
        16歳未満のお客様の情報の保護についても最大限の注意を払います。16歳未満のお客様の個人情報については、必ず保護者の方の同意の下にご提供いただきますようお願いいたします。
      </p>
      <p>&nbsp; </p>
      <h3>Ⅲ 当社が取り扱う個人データの第三者提供に関する事項</h3>
      <p>
        当社では、当社にご提供いただきました個人情報は、当社社内でのみ利用し、第三者に開示することはいたしません。ただし、次のいずれかに該当する場合には、その限りではありません。
      </p>
      <h3>1． ご本人の同意がある場合（特定個人情報を除く）</h3>
      <h3>2．当社と機密保持契約を締結している協力企業等、業務委託契約を締結している業務委託会社、および当社の子会社に対して、お取引先様に明示した取得目的を実施するために個人情報を開示する必要が有る場合</h3>
      <p>（例：納入先として配送業者に配送を委託する場合）</p>
      <h3>3．ライセンス発行元、製品供給元等と当社とのライセンス契約や製品保守契約等のユーザ登録・報告等の契約義務履行のために、ライセンス発行元・製品供給元等への報告・提供が必要な場合</h3>
      <h3>4．個人情報保護法第23条1項に基づき、提供に応じなければならない場合</h3>
      <h3>5．匿名加工情報を作成した場合</h3>
      <ol className={classes.decimalLeadingZero}>
        <li>
          匿名加工情報の作成<br />
          当社は、研究、開発及びサービス向上等の目的で、匿名加工情報（法令に定める措置を講じて特定の個人を識別することができないように個人情報を加工して得られる個人に関する情報であって、当該個人情報を復元することができないようにしたもの）を作成する場合には、以下の対応を行います。
          <ol className={classes.privacyLowerLatin}>
            <li>法令で定める基準に従って、適正な加工を施すこと</li>
            <li>法令で定める基準に従って、削除した情報や加工の方法に関する情報の漏えいを防止するために安全管理措置を講じること</li>
            <li>
              作成した匿名加工情報に含まれる情報の項目を公表すること<br />
              ⇒ <a href="pdf/tokumeikakou.pdf" target="_blank">匿名加工情報の作成について</a>
            </li>
            <li>作成の元となった個人情報の本人を識別するための行為をしないこと</li>
          </ol>
        </li>
        <li>
          匿名加工情報の利用<br />
          当社は、匿名加工情報を研究、開発及びサービス向上等の目的の範囲内で利用いたします。匿名加工情報を利用するときは、元の個人情報に係る本人を識別（再識別）する目的で他の情報と照合することは行いません。
        </li>
        <li>
          匿名加工情報の提供<br />
          当社は、研究、開発及びサービス向上等の目的で、匿名加工情報を第三者に提供する場合には、提供しようとする匿名加工情報に含まれる個人に関する情報の項目と提供の方法を公表するとともに、提供先となる第三者に対して、提供する情報が匿名加工情報であることを明示します。
        </li>
        <li>
          当社で作成する匿名加工情報に含まれる情報の項目、第三者に提供する匿名加工情報に含まれる情報の項目と提供の方法については、こちら (
          <a href="https://www.jiem.co.jp/privacy/pdf/anonymize.pdf" target="_blank">https://www.jiem.co.jp/privacy/pdf/anonymize.pdf</a>
          ) をご覧ください。
        </li>
      </ol>
      <p>&nbsp; </p>
      <h3>Ⅳ 個人情報の取り扱いの委託</h3>
      <p>
        当社は、ご提供いただいた個人情報について、利用目的を遂行するために外部に委託することがあります。委託先については、情報セキュリティおよび個人情報保護に関する管理水準を評価し、必要な安全対策を実施している企業を選定し、適切に管理・監督を行ないます。
      </p>
      <p>&nbsp; </p>
      <h3>Ⅴ 個人情報提供の任意性</h3>
      <p>
        個人情報の当社への提供は任意でございますが、ご提供いただけなかった場合には、サービス等をご利用できない場合がございます。あらかじめご了解ください。
      </p>
      <p>&nbsp; </p>
      <h3>Ⅵ クッキー（Cookie）について</h3>
      <p>
        当社のWebサイトには、「クッキー（Cookie）」(※2)と呼ばれる技術を利用したページがあります。クッキーにより当社が取得する情報のうち、個人を特定できる情報、または他の情報と組み合わせることにより個人を特定できる情報については、個人情報の保護に関するガイドラインに沿って取り扱われます。また、クッキー情報等を利用しているWebサイトには、「利用規約」にその旨が記載されています。
      </p>
      <p>&nbsp; </p>
      <p>クッキーは以下の目的で使用されます。</p>
      <ol className={classes.decimalLeadingZero}>
        <li>お客様が当社のWebサイト上のサービスにログインされるとき、保存されているお客様の登録情報を参照して、お客様ごとにカスタマイズされたサービスの提供</li>
        <li>お客様が興味を持っている内容や、当社のWebサイト上での利用状況をもとに、最も適切な広告をお客様が利用する他社Webサイト上での表示</li>
        <li> 当社のWebサイトの利用者数やトラフィックの調査</li>
        <li>当社のサービスの改善</li>
        <li>セキュリティ保持のため、ご利用から一定の時間が経過したお客様に対してパスワードの再入力（再認証）を促す</li>
      </ol>
      <p>&nbsp; </p>
      <p>
        なお、当社は、当社の広告の配信を委託する第三者への委託に基づき、当該第三者を経由して、当社のクッキーを保存し参照する場合があります。また、お客様のブラウザーの設定により、クッキーの設定を変更してクッキーの機能を無効にすることができますが、その結果当社のWebサイト上のサービスの一部がご利用いただけなくなる場合があります。
      </p>
      <p>&nbsp; </p>
      <p>
        (※2)クッキーとは、Webサーバーからお客様のブラウザーに送信されるテキストデータのことをいいます。
        Webサーバーが、クッキーを参照しお客様のコンピュータを識別することによりお客様は効率的に当社のWebサイトを利用することができます。
      </p>
      <p>&nbsp; </p>
      <h3>●個人情報保護基本方針に関する問合せ及び、苦情・相談、開示・訂正・削除、利用・提供の拒否のお問い合わせ窓口</h3>
      <h4>弊社の保有する個人情報に関する利用目的、苦情・相談、開示・訂正・削除、利用・提供の拒否などの請求は以下のリンクにある「個人情報お問い合わせ窓口」にご連絡ください。</h4>
      <h3>●個人情報お問い合わせ窓口</h3>
      <h4><b>【弊社の個人情報取り扱いに関するお問い合わせ】</b></h4>
      <div className={classes.contactInfo}>
        <h4>株式会社教育測定研究所 個人情報取り扱いに関する苦情・相談窓口</h4>
        <dl>
          <dt>電話：</dt>
          <dd>03-6897-3856</dd>
        </dl>
        <dl>
          <dt>E-mail：</dt>
          <dd>
            <a href="&#109;ai&#108;&#116;o&#58;&#99;&#111;&#110;t&#97;&#99;&#116;&#64;j&#105;e&#109;&#46;c&#111;.&#106;p">
              &#99;&#111;&#110;t&#97;ct&#64;&#106;i&#101;m&#46;&#99;&#111;.&#106;p
            </a>
          </dd>
        </dl>
        <p>午前9時30分～午後5時30分（土・日曜日、祝祭日、年末年始は除く）</p>
      </div>
      <h4><b>【認定個人情報保護団体の名称及び、苦情の解決の申出先】</b></h4>
      <p>弊社は、個人情報保護委員会の認定を受けた下記の認定個人情報保護団体の対象事業者です。同協会では、対象事業者の個人情報に関する苦情を受け付けております。</p>
      <p>
        認定個人情報保護団体の名称： 一般社団法人日本情報システム・ユーザー協会（JUAS）<br />
        苦情の解決の申出先： 認定個人情報保護団体事務局 苦情相談室<br />
        住所： 東京都中央区築地 1-13-14 NBF東銀座スクエア2階<br />
        電話番号：03-6264-1318<br />
        受付時間：午前10時00分〜午後4時00分（土・日曜日、祝祭日は除く）<br />
        ※弊社の商品・サービスに関するお問い合わせ先ではございません。
      </p>
    </div>
  );
};

export default PrivacyPolicy;